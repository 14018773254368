//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import cloneDeep from 'lodash/cloneDeep'
export default {
  name: 'PopularContent',
  props: {
    popularContents: {
      type: Object,
      default: Object
    }
  },
  // serverCacheKey: props => 'PopularContent::' + JSON.stringify(props.popularContents),
  data () {
    return {
      contents: [],
      titleData: [
        {
          text: 'เนื้อหา',
          class: ''
        },
        {
          text: 'ยอดนิยม',
          class: 'text-primary'
        }
      ],
      screenWidth: 1920
    }
  },
  computed: {
    windowWidth () {
      return this.screenWidth
    },
    isBooking () {
      return this.$store.state.handle.isBooking
    }
  },
  created () {
    this.contents = cloneDeep(this.popularContents.items)
  },
  async mounted () {
    if (this.permissionUser() && this.isBooking.pageUuidArray.length) {
      const array = this.isBooking.pageUuidArray.map(v => v.pageUuid)
      if (this.contents.some(content => array.includes(content.pageUuid))) {
        await this.isBooking.pageUuidArray.forEach(async (item) => {
          if (this.contents[item.index] && this.contents[item.index].pageUuid === item.pageUuid) {
            const payload = {}
            payload.userRef = this.$authUser.userRef() // this.$store.state.profile.user ? this.$store.state.profile.user.userRef : ''
            payload.pageUuid = item.pageUuid
            const res = await this.$store.dispatch('contents/getContentBookmark', payload)
            this.contents[item.index].isBookmark = res
          }
        })
        // await this.clearIsBookmark()
      }
    }
    this.screenWidth = window.innerWidth
    window.addEventListener('resize', () => {
      this.screenWidth = window.innerWidth
    })
  },
  methods: {
    onClickBookmark (pageUuid, event) {
      event.stopPropagation()
      const index = this.contents.map(v => v.pageUuid).indexOf(pageUuid)
      this.handleBookMark(pageUuid, index)
    },
    handleClickTracking (item) {
      this.clickTracking({
        title: item.name,
        pageUuid: item.pageUuid,
        pageType: item.pageType,
        sectionName: 'เนื้อหายอดนิยม',
        url: item.url,
        gtmDataLayer: item.gtmDataLayer,
        teamName: process.env.GTM_EVENT_CATEGORY_TEAM
      })
    }
  }
}
